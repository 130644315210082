import { FormEvent } from 'react'
import { csrfToken } from '../../helpers'
import styles from './index.module.scss'

const SignOut = ({ url }: { url: string }) => {
    const submit = (event: FormEvent) => {
        event.preventDefault()
        event.stopPropagation()

        if (confirm('Are you sure?')) {
            const target = event.target as HTMLFormElement
            target.submit()
        }
    }

    return (
        <form method="POST" onSubmit={submit} action={url}>
            <input type="hidden" name="_method" value="DELETE" />
            <input type="hidden" name="authenticity_token" value={csrfToken} />
            <button type="submit" className={styles.Button}>
                Sign Out
            </button>
        </form>
    )
}

export { SignOut }
