// import locale from '../../../config/locales/'

const csrfToken = (() => {
    return document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content')
})()

// const t = (key: string) => {
//     const path = key.split('.')
//     let i18n = locale['ar']
//     path.forEach((p) => {
//         if (i18n[p]) {
//             i18n = i18n[p]
//         }
//     })
//     return i18n
// }

const getAllAttributes = (element: Element) => {
    return element.getAttributeNames().reduce((acc, name) => {
        return { ...acc, [name]: element.getAttribute(name) }
    }, {})
}

export { csrfToken, getAllAttributes }
