import { useEffect, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import styles from './index.module.scss'

const animatedComponents = makeAnimated()

const ZoneSelect = () => {
    const [selectedValues, setSelectedValues] = useState('')
    const [options, setOptions] = useState([])

    const handleChange = (selectedValues) => {
        setSelectedValues(selectedValues)
    }

    const onClick = () => {
        const ids = selectedValues.map((s) => `zone_id[]=${s.value}`)
        const url = `/get_lots.xlsx.xlsx?${ids.join('&')}`
        window.location = url
    }

    useEffect(() => {
        const controller = new AbortController()

        const fetchData = async () => {
            let data = []
            try {
                const response = await fetch('/zones.json', {
                    signal: controller.signal,
                })
                data = await response.json()
            } catch (e) {
                if (!controller.signal.aborted) {
                    console.error(e)
                }
            }

            data.forEach((zone) => {
                setOptions((options) => [
                    ...options,
                    { value: zone.id, label: zone.name },
                ])
            })
        }

        fetchData()

        return () => {
            controller.abort()
        }
    }, [])

    return (
        <div className={styles.w96}>
            <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={options}
                onChange={handleChange}
                className={styles.w96}
            />
            <button onClick={onClick} className={styles.Button}>
                Download Excel File
            </button>
        </div>
    )
}

export { ZoneSelect }
