import { WMSTileLayer } from 'react-leaflet'

const url = '/api/tiles'

type Layers = {
    layer?: string
    layers?: string[]
}

type LayerProps = Layers & {
    url: string
    token?: string
    params?: {
        [key: string]: any
    }
    bgcolor?: string
}

const Layer = ({ params, ...props }: LayerProps) => {
    return (
        <WMSTileLayer
            params={{
                transparent: true,
                format: 'image/png',
                version: '1.3.0',
                tiled: true,
                ...params,
            }}
            {...props}
        />
    )
}

const APILayer = ({ layer, layers, ...props }: LayerProps) => {
    return (
        <Layer
            url={url}
            layers={
                (layers && layers.map((layer) => `aina:${layer}`)) || [
                    `aina:${layer}`,
                ]
            }
            {...props}
        />
    )
}

export { APILayer, Layer, Layers }
