import L, { CRS } from 'leaflet'
import { MapContainer as MC, LayerGroup, Marker, Popup } from 'react-leaflet'
import styled from 'styled-components'
import { WMSLayer } from './WMSLayer'
import { Layer } from './APILayer'

import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'
import 'leaflet/dist/leaflet.css'
import { useEffect, useMemo, useRef, useState } from 'react'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
})

const MapContainer = styled(MC)`
    width: 100%;
    height: 100%;
`

const Container = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
`

const availableLayers = {
    'divindus:sat-images': 'Images Satellite',
    'divindus:shapes': 'Plans',
}

type Zone = {
    id: number
    name: string
    region: Region
    type: string
    location: [number, number]
}

type Region = 'Centre' | 'Est' | 'Ouest' | 'Sud'

interface GroupedZones {
    [key: Region]: Zone[]
}

const Map = ({ lot }: { lot?: [number, number] }) => {
    const [selectedLayers, setSelectedLayers] = useState<string[]>([
        'divindus:base',
    ])

    const [zones, setZones] = useState<Zone[]>([])
    const mapRef = useRef()

    const toggleLayer = (layer: string) => {
        setSelectedLayers((layers) => {
            return layers.includes(layer)
                ? layers.filter((l) => l != layer)
                : [...layers, layer]
        })
    }

    const groupedZones: GroupedZones = useMemo(() => {
        return zones.reduce((group, zone) => {
            const { region } = zone
            group[region] ??= []
            group[region].push(zone)

            return group
        }, {})
    }, [zones])

    useEffect(() => {
        const controller = new AbortController()

        const fetchData = async () => {
            try {
                const response = await fetch('/zones.json', {
                    signal: controller.signal,
                    headers: {
                        Accept: 'application/json',
                    },
                })

                const data: Zone[] = await response.json()

                setZones(data)
            } catch (e) {
                if (e.name == 'AbortError') {
                    console.info('aborted request')
                }
            }
        }

        fetchData()

        return () => controller.abort()
    }, [])

    const handleZoneSelect = (id: number) => {
        const zone: Zone = zones.find((z) => z.id == id)

        mapRef.current?.flyTo(zone.location, 16)
    }

    return (
        <Container>
            <div
                className="absolute top-0 right-0 w-fit mt-2 mr-2 flex flex-col gap-2"
                style={{ zIndex: 800 }}
            >
                <div className="bg-white border rounded-sm p-2 flex flex-col gap-1">
                    {Object.keys(availableLayers).map((layer) => {
                        const label = availableLayers[layer]

                        return (
                            <label key={layer}>
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    onChange={() => {
                                        toggleLayer(layer)
                                    }}
                                    checked={selectedLayers.includes(layer)}
                                />
                                {label}
                            </label>
                        )
                    })}
                </div>

                <div className="bg-white border rounded-sm p-2 flex flex-col gap-1">
                    <select
                        onChange={(e) =>
                            handleZoneSelect(parseInt(e.target.value))
                        }
                    >
                        <option value=""></option>
                        {Object.keys(groupedZones).map((region) => {
                            const zones = groupedZones[region]
                            return (
                                <optgroup key={region} label={region}>
                                    {zones.map((zone: Zone) => {
                                        const { id, name, type } = zone
                                        return (
                                            <option key={id} value={id}>
                                                {name} <small>{type}</small>
                                            </option>
                                        )
                                    })}
                                </optgroup>
                            )
                        })}
                    </select>
                </div>
            </div>
            <MapContainer
                center={[36.737232, 3.086472]}
                zoom={6}
                crs={CRS.EPSG4326}
                ref={mapRef}
            >
                <WMSLayer
                    clickOn={lot}
                    url="/proxy/wms"
                    layers={['divindus:oran']}
                />
                <LayerGroup>
                    {zones.map(({ id, location, name, region, type }) => {
                        return (
                            <Marker key={id} position={location}>
                                <Popup>
                                    <h3 className="text-lg font-semibold">
                                        {name}{' '}
                                        <small className="text-gray-500 text-sm">
                                            {type}
                                        </small>
                                    </h3>
                                    <p className="text-center text-sm text-gray-800">
                                        {region}
                                    </p>
                                </Popup>
                            </Marker>
                        )
                    })}
                </LayerGroup>
                <LayerGroup key={selectedLayers.sort().join('-')}>
                    <Layer
                        url="/proxy/wms"
                        layers={(() => {
                            const layers = selectedLayers.filter(
                                (l) => l != 'divindus:road_network'
                            )
                            layers.push('divindus:road_network')

                            return layers
                        })()}
                        params={{ transparent: false }}
                        bgcolor="0x98CEF2"
                    />
                </LayerGroup>
            </MapContainer>
        </Container>
    )
}

export { Map }
