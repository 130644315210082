const titleize = (str) => {
    return typeof str == 'string'
        ? str.replace(/_/g, ' ').replace(/\b\w/g, function (word) {
              return word.toUpperCase()
          })
        : str
}

const TableRow = ({
    title: propTitle,
    value: propValue,
}: {
    title: string
    value: boolean
}) => {
    const title = titleize(propTitle)

    const value =
        propValue == true
            ? 'Oui'
            : propValue == false
            ? 'Non'
            : propValue == null
            ? 'Non renseigné'
            : titleize(propValue)
    return (
        <tr>
            <td className="p-2 border whitespace-nowrap font-bold">{title}</td>
            <td className="p-2 border whitespace-nowrap">{value}</td>
        </tr>
    )
}

const PropsTable = ({ id, ...props }: { id: number }) => {
    return (
        <>
            <table>
                <tbody>
                    {Object.keys(props).map((prop) => {
                        return (
                            <TableRow
                                key={prop}
                                title={prop}
                                value={props[prop]}
                            />
                        )
                    })}
                </tbody>
            </table>
            <a
                href={`/lots/${id}/edit`}
                target="_blank"
                rel="noopener noreferrer"
                className="button is-primary is-outlined"
            >
                Modifier
            </a>
        </>
    )
}

export { PropsTable }
