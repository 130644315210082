import { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'

const Select = styled.select`
    width: 100%;
`

const LotsAssign = ({
    geojson,
    afterUpdate,
}: {
    geojson: any
    afterUpdate: any
}) => {
    const [lots, setLots] = useState([])
    const [selectedLot, setSelectedLot] = useState(null)
    useEffect(() => {
        if (lots.length === 0) {
            fetch('/lots.json?geom=null')
                .then((res) => res.json())
                .then((data) => setLots(data))
        }
    }, [lots])

    const grouped = useMemo(() => groupByZone(lots), [lots])
    const handleSubmit = (e) => {
        e.preventDefault()
        fetch(`/lots/${selectedLot}.json`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': document
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute('content'),
            },
            body: JSON.stringify({
                lot: {
                    geom: JSON.stringify(geojson),
                },
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                afterUpdate()
            })
    }

    // group lots by zone.name
    return (
        lots.length > 0 && (
            <div>
                <Select
                    name="lots"
                    id="lots"
                    onChange={(e) => setSelectedLot(e.target.value)}
                >
                    {Object.keys(grouped).map((zone) => (
                        <optgroup key={zone} label={zone}>
                            {grouped[zone].map((lot) => (
                                <option key={lot.id} value={lot.id}>
                                    {lot.lot_number} - {lot.activity}
                                </option>
                            ))}
                        </optgroup>
                    ))}
                </Select>
                <button onClick={handleSubmit}>Enregistrer</button>
            </div>
        )
    )
}

const groupByZone = (lots) => {
    return lots.reduce((acc, lot) => {
        if (!acc[lot.zone.name]) {
            acc[lot.zone.name] = []
        }
        acc[lot.zone.name].push(lot)
        return acc
    }, {})
}

export { LotsAssign }
