import { Marker } from 'leaflet'
import * as WMS from 'leaflet.wms'
import { useEffect } from 'react'
import * as ReactDOM from 'react-dom'
import { useMap } from 'react-leaflet'
import { LotsAssign } from './LotsAssign'
import { PropsTable } from './PropsTable'

declare const window: any

const WMSLayer = ({
    url,
    layers,
    options,
    clickOn,
}: {
    url: string
    options?: { [key: string]: string }
    layers: string[]
    clickOn?: [number, number]
}) => {
    const L = window.L
    const map = useMap()

    useEffect(() => {
        if (clickOn) {
            map.setView(clickOn, 16)
            const marker = new Marker(clickOn)
            marker.addTo(map)
        }
    }, [])

    const WMSsource = WMS.Source.extend({
        getFeatureInfoParams: function(point, layers) {
            // Hook to generate parameters for WMS service GetFeatureInfo request
            let wmsParams, overlay
            if (this.options.untiled) {
                // Use existing overlay
                wmsParams = this._overlay.wmsParams
            } else {
                // Create overlay instance to leverage updateWmsParams
                overlay = this.createOverlay(true)
                overlay.updateWmsParams(this._map)
                wmsParams = overlay.wmsParams
                wmsParams.layers = layers.join(',')
            }
            const infoParams = {
                request: 'GetFeatureInfo',
                query_layers: `divindus:lots,${layers.join(',')}`,
                X: Math.round(point.x),
                Y: Math.round(point.y),
            }
            return L.extend({}, wmsParams, infoParams)
        },
        showFeatureInfo: function(latlng, info) {
            // parse json
            const json = JSON.parse(info)
            const properties = json?.features[0]?.properties
            if (properties == undefined) return

            const randomId =
                Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15)
            const html = `
                <div
                    id="${randomId}"
                    style="min-width: 150px; width: fit-content;"
                />
            `
            const popup = this._map.openPopup(html, latlng, {
                offset: new L.Point(-75, 8),
                maxWidth: 'auto',
            })
            let component

            if (properties?.zone_id != undefined) {
                component = <PropsTable {...properties} />
            } else if (properties != undefined) {
                component = (
                    <LotsAssign
                        geojson={json?.features[0]?.geometry}
                        afterUpdate={() => {
                            map.closePopup()
                            map.eachLayer((layer) => {
                                if (layer.wmsParams != undefined) {
                                    layer.redraw()
                                }
                            })
                        }}
                    />
                )
            }
            const element = document.getElementById(randomId)
            ReactDOM.render(component, element)
            popup.on('popupclose', () => {
                ReactDOM.unmountComponentAtNode(element)
            })

            element.parentElement.style.width = `${element.offsetWidth + 1}px`
        },
    })
    const source = new WMSsource(url, {
        ...options,
        info_format: 'application/json',
        tiled: true,
        transparent: true,
        format: 'image/png',
        version: '1.3.0',
    })
    for (const name of layers) {
        source.getLayer(name).addTo(map)
    }
    return null
}

export { WMSLayer }
