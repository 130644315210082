// import Rails from '@rails/ujs'
// import Turbolinks from 'turbolinks'
// import * as ActiveStorage from '@rails/activestorage'
// import '../channels'

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()

import { ZoneSelect, Map, SignOut } from '../components'
import { registerCustomElement, Components } from 'react-custom-mount'

const components: Components = {
    'select-zones': ZoneSelect,
    'aina-map': Map,
    'sign-out': SignOut,
}

document.addEventListener('DOMContentLoaded', function () {
    Object.entries(components).forEach(([tag, component]) => {
        registerCustomElement(component, tag)
    })
})
